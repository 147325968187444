.wrapper {
  display: flex;
  padding: 12px 24px;
  justify-content: space-between;
  background: #f6f6f6;
  align-items: center;
}
.search {
  display: flex;
  gap: 12px;
  padding: 5px 2px;
  align-items: center;
  justify-content: space-between;
  > .citySearch {
    display: flex;
    gap: 4px;
    align-items: center;
  }
  > .otherSearch {
    display: flex;
    align-items: center;
  }
  > .dateSelect {
    display: flex;
    align-items: center;
  }
}
.operatorSearch {
  display: flex;
  align-items: center;
  gap: 8px;
}
.expiredToggle {
  display: flex;
  align-items: center;
  gap: 16px;
  > span {
    color: #292929;
    text-align: center;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
}
.Btn {
  display: flex;
  height: 40px;
  padding: 11px 16px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 8px;
  border: none;
  background: #7b2aff;
  > span {
    color: #fff;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
}
