.tripDetails {
  ul {
    padding-left: 0;
    list-style: none;
    display: grid;
    li {
      padding-left: 30px;
      margin-top: 2%;
      position: relative;

      &:first-child:before {
        content: "";
        position: absolute;
        top: 18px;
        bottom: -35px;
        left: 9px;
      }
    }
    .noImagePickup {
      background: url("../../../../images/pickup_icon.svg") no-repeat left top;
      font-family: "SFProText";
      color: black;
      background-size: 14px;
    }
    .textFormat {
      font-family: "SFProText";
      font-size: 14px;
      font-weight: 400;
      color: #454545;
    }
    .modalTextFormat {
      font-family: "SFProText";
      font-size: 16px;
      font-weight: 500;
      columns: #292929;
    }
    .textBusNumber {
      font-family: "SFProText";
      font-size: 18px;
      font-weight: 700;
    }
    .underlyingtextFormat {
      font-weight: 400;
      font-family: "SFProText";
      color: #b2b2b2;
      font-size: 13px;
    }
    .underlyingtextBlue {
      font-weight: 400;
      font-family: "SFProText";
      color: #7b2aff;
      font-size: 11px;
    }
    .pickup {
      padding-left: 0px;
      margin-top: 10px;
      margin-bottom: 20px;
      // background: url(https://d3bjx0rj820nz0.cloudfront.net/new.png) no-repeat left top;
      // background-size: 50px;
    }

    .noImageDrop {
      background: url("../../../../images/drop_icon.svg") no-repeat left top;
      font-family: "SFProText";
      color: black;
      background-size: 14px;
    }
    .drop {
      padding-left: 0px;
      margin-top: 1 0px;
    }

    p {
      width: 100%;
      word-break: break-word;
    }
  }
}
.modal {
  font-family: SFProText;
  font-weight: 600;
  margin: 1px;
  font-size: 16px;
}

.modalSecond {
  margin-top: 5%;
  font-family: SFProText;
  font-size: 13px;
  font-weight: 500;
  line-height: 17px;
  letter-spacing: 0px;
  text-align: left;
}
