.editTable {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  > div {
    width: 120px;
    cursor: pointer;
    display: flex;
    padding: 6px 12px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 8px;
    background: #dfedff;
    > span {
      color: #1b8ef8;
      text-align: center;
      font-family: Poppins;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
  }
}
.statusDiv {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 6px;
  .statusActive {
    width: 6px;
    height: 6px;
    flex-shrink: 0;
    border-radius: 17px;
    background: #69ac39;
  }
  .statusInActive {
    width: 6px;
    height: 6px;
    flex-shrink: 0;
    border-radius: 12px;
    background: #dd800e;
  }
}
.header {
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 12px 24px;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  > span {
    color: #292929;
    font-family: Poppins;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  > div {
    cursor: pointer;
    display: flex;
    padding: 6px 12px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    align-self: stretch;
    border-radius: 8px;
    background: #7b2aff;
    > span {
      color: #fff;
      text-align: center;
      font-family: Poppins;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
  }
}
.createModal {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  > .error {
    color: red;
    font-weight: 600;
  }
  span {
    color: #292929;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  > div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 7px;
  }
  .typeSelector {
    display: flex;
    gap: 16px;
    flex-direction: column;
    > div {
      display: flex;
      gap: 7px;
      flex-direction: column;
    }
  }
  .DatesSelector {
    flex-direction: row;
    display: flex;
    gap: 7px;
    > div {
      gap: 7px;
      display: flex;
      flex-direction: column;
    }
  }
}
.modalMessages {
  display: flex;
  padding: 24px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;
  > div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 16px;
    align-self: stretch;
    > span:nth-child(1) {
      color: #292929;
      font-feature-settings: "ss01" on, "cv01" on, "cv11" on;
      font-family: Poppins;
      font-size: 22px;
      font-style: normal;
      font-weight: 600;
      line-height: 20px; /* 90.909% */
    }
    > span:nth-child(2) {
      color: #454545;
      font-family: Poppins;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }
  > button {
    display: flex;
    height: 48px;
    padding: 11.5px 0px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    align-self: stretch;
    border-radius: 8px;
    background: #dfedff;
    border: none;
    > span {
      color: #1b8ef8;
      text-align: center;
      font-family: Poppins;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
  }
}
